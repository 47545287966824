import Repository from '@/modules/common/utils/Repository.js'
import store from "@/store";

export default {
  get(botName, extraFilters = {}) {
    extraFilters['bot_name'] = botName
    return Repository.dbService.get('/library/search', {params: extraFilters})
  },

  getDocument(botId, docId, quantity) {
    let params = {'bot_name': botId, 'doc_id': docId}
    if (quantity != undefined) {
      params['quantity'] = quantity
    }
    return Repository.dbService.get('/library/document', {params: params})
  },

  getDocumentByTags(botId, tags, quantity) {
    let params = {'bot_name': botId, 'tags': tags}
    if (quantity != undefined) {
      params['quantity'] = quantity
    }
    return Repository.dbService.get('/library/document-by-tags', {params: params})
  },

  remove(botId, docId) {
    let params = { 'bot_name': botId, 'doc_id': docId }
    return Repository.dbService.delete('/library/remove', {params: params})
  },

  getBotTags(botName) {
    return Repository.dbService.get('/library/bot_tags', {
      params: {
        'bot_name': botName
      }
    })
  },

  downloadDocument(fileStoredName) {
    return Repository.dbService.get('/library/download_document', {
      params: {
        'file_stored_name': fileStoredName,
        'bot_name': store.getters.getInstance
      },
      responseType: 'arraybuffer'
    })
  },

  getPassageFeedback(passageId, botName) {
    return Repository.dbService.get('/library/get_passage_feedback', {
      params: {
        'passage_id': passageId,
        'bot_name': botName
      }
    })
  },

  updatePassageFeedback(passageData, botName) {
    return Repository.dbService.post('/library/update_passage_feedback', passageData, {
      params: {
        'bot_name': botName
      }
    })
  },

  setPassageFavorite(botName, passageId, value) {
    let data = {
      'passage_id': passageId,
      'value': value
    }
    return Repository.dbService.post('/library/set_passage_as_favorite', data, {
      params: {
        'bot_name': botName
      }
    })
  },

  setAllFavorite(botName, docId, value) {
    let data = {
      'doc_id': docId,
      'value': value
    }
    return Repository.dbService.post('/library/set_all_passage_as_favorite', data, {
      params: {
        'bot_name': botName
      }
    })
  }
}