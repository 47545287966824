<template>
  <b-modal :hide-footer="true" id="smartSearchModal">
    <b-tabs @input="emptyList" content-class="mt-3">
      <b-tab title="Search by Q&A" active>
        <b-row class="m-0">
          <b-input-group class="qa-input-group">
            <b-form-input
              v-model="qaQuestion"
              class="smart-search-modal-question-input"
              size="lg"
              placeholder="Search for a question"
            >
            </b-form-input>
            <b-button
              class="smart-search-button"
              @click="searchQuestion('qa', qaQuestion)"
            >
              <span
                ><img
                  src="/assets/images/icon_askQuestion_search.svg"
                  alt="search"
              /></span>
            </b-button>
          </b-input-group>
        </b-row>
        <vue-tags-input
          class="ask-tags-input autocomplete-container"
          v-model="tagsInput.currentQATag"
          :tags="tagsInput.tagsSearch"
          :autocomplete-items="filteredQATags"
          :add-only-from-autocomplete="true"
          @tags-changed="(newTags) => (tagsInput.tagsSearch = newTags)"
        />
        <div>
            <b-alert show v-if="displayQAAlert" variant="warning">We've noticed that you tried to make a search only with tags.
          This is not possible on QA searchs, try on Library searchs.
        </b-alert>
          <preloader class="d-block my-6" v-if="useLoader" :type="'modal'" />
          <b-row
            class="
              ml-0
              mr-0
              d-flex
              justify-content-between
              smart-search-results-row
            "
          >
            <div>
              <span class="fz-16 openSans-400"
                >{{ this.responses.length }} matches</span
              >
            </div>
            <div>
              <span class="fz-16 openSans-100 smart-search-selected-answers">
                <span class="smart-search-selected-count openSans-400"
                  >{{ selectedAnswers.length }}
                </span>
                selected answers
              </span>
              <button
                type="button"
                :disabled="selectedAnswers.length == 0"
                @click="applyResponses()"
                class="
                  btn
                  openSans-600
                  fz-12
                  ta-center
                  button-primary
                  btn-primary btn-md
                "
              >
                Use as response
              </button>
            </div>
          </b-row>
          <div class="smart-search-questions-container">
            <div
              class="smart-search-question-block"
              v-for="question in responses"
              :key="question.input_rid"
            >
              <div class="margin-smart-search-question-title">
                <span class="openSans-100 fz-18 smart-search-question">{{
                  question.question
                }}</span>
              </div>
              <b-row
                class="ml-0"
                v-for="(answer, index) in question.answers"
                :key="answer.response_id"
              >
                <div class="smart-search-answer-checkbox">
                  <b-form-checkbox
                    :value="answer"
                    v-model="selectedAnswers"
                  ></b-form-checkbox>
                </div>
                <div class="smart-search-answer-content w-100">
                  <span class="openSans-100 fz-16" v-html="answer.answer_text">
                  </span>
                  <b-row class="smart-search-visit-source cursor-pointer">
                    <span
                      class="library-document-bottom-file openSans-400 fz-12"
                      >View source</span
                    >
                    <img
                      class="knowledge-book"
                      :src="'/assets/images/icon_book.svg'"
                    />
                  </b-row>
                </div>
                <div class="smart-search-tags-container">
                  <div class="smart-search-tags-title-container">
                    <span class="openSans-400 fz-12">Tags:</span>
                  </div>
                  <span
                    class="openSans-400 fz-12 smart-search-tags"
                    v-for="tag in answer.tags"
                    :key="tag"
                  >
                    {{ tag }}
                  </span>
                </div>
                <hr
                  v-if="index != Object.keys(question.answers).length - 1"
                  class="smart-search-answer-separator"
                />
              </b-row>
            </div>
          </div>
        </div>
      </b-tab>
      <b-tab title="Search by Library">
        <b-row class="m-0">
          <b-input-group class="qa-input-group">
            <b-form-input
              v-model="libraryQuestion"
              class="smart-search-modal-question-input"
              size="lg"
              placeholder="Search for a question"
            >
            </b-form-input>
            <b-button
              class="smart-search-button"
              @click="searchQuestion('library', libraryQuestion)"
            >
              <span
                ><img
                  src="/assets/images/icon_askQuestion_search.svg"
                  alt="search"
              /></span>
            </b-button>
          </b-input-group>
        </b-row>
        <vue-tags-input
          class="ask-tags-input autocomplete-container"
          v-model="tagsInput.currentLibraryTag"
          :tags="tagsInput.tagsSearch"
          :autocomplete-items="filteredLibraryTags"
          :add-only-from-autocomplete="true"
          @tags-changed="(newTags) => (tagsInput.tagsSearch = newTags)"
        />
        <div>
          <preloader class="d-block my-4" v-if="useLoader" :type="'modal'" />
          <b-row
            class="
              ml-0
              mr-0
              d-flex
              justify-content-between
              smart-search-results-row
            "
          >
            <div>
              <span class="fz-16 openSans-400"
                >{{ this.libraryPassages.length }} matches</span
              >
            </div>
            <div>
              <span class="fz-16 openSans-100 smart-search-selected-answers">
                <span class="smart-search-selected-count openSans-400">{{
                  selectedAnswers.length
                }}</span>
                selected answers
              </span>
              <button
                type="button"
                :disabled="selectedAnswers.length == 0"
                @click="applyResponses()"
                class="
                  btn
                  openSans-600
                  fz-12
                  ta-center
                  button-primary
                  btn-primary btn-md
                "
              >
                Use as response
              </button>
            </div>
          </b-row>
          <div class="smart-search-questions-container">
            <div
              class="smart-search-question-block"
              v-for="(passage, index) in libraryPassages"
              :key="passage.doc_id + ' ' + index"
            >
              <div class="margin-smart-search-question-title">
                <span class="openSans-100 fz-18 smart-search-question">{{
                  passage.title
                }}</span>
              </div>
              <b-row
                class="ml-0"
                v-for="passage in passage.passages"
                :key="passage.id"
              >
                <div class="smart-search-answer-checkbox">
                  <b-form-checkbox
                    :value="passage"
                    v-model="selectedAnswers"
                  ></b-form-checkbox>
                </div>
                <div class="smart-search-answer-content">
                  <span v-html="passage.html" class="openSans-100 fz-16">
                  </span>
                  <b-row class="smart-search-visit-source cursor-pointer">
                    <span
                      class="library-document-bottom-file openSans-400 fz-12"
                      >View source</span
                    >
                    <img
                      class="knowledge-book"
                      :src="'/assets/images/icon_book.svg'"
                    />
                  </b-row>
                </div>
                <div class="smart-search-tags-container">
                  <div class="smart-search-tags-title-container">
                    <span class="openSans-400 fz-12">Tags:</span>
                  </div>
                  <span
                    class="openSans-400 fz-12 smart-search-tags"
                    v-for="tag in passage.tags"
                    :key="tag"
                  >
                    {{ tag }}
                  </span>
                </div>
                <hr class="smart-search-answer-separator" />
              </b-row>
            </div>
          </div>
        </div>
      </b-tab>
    </b-tabs>
  </b-modal>
</template>

<script>
import LibraryService from "@/modules/library/services/LibraryService.js";
import AskService from "@/modules/knowledge/services/AskService.js";
import Preloader from '@/modules/common/components/Preloader.vue'

export default {
  components: { Preloader },
  data() {
    return {
      tags: [],
      qaQuestion: "",
      libraryQuestion: "",
      botTags: [],
      tagsInput: {
        currentQATag: "",
        currentLibraryTag: "",
        tagsSearch: [],
      },
      questions: [],
      responses: [],
      libraryPassages: [],
      selectedAnswers: [],
      useLoader: false,
      displayQAAlert: false,
    };
  },
  computed: {
    filteredQATags() {
      return this.botTags.filter((tag) => {
        return (
          tag.text
            .toLowerCase()
            .indexOf(this.tagsInput.currentQATag.toLowerCase()) !== -1
        );
      });
    },
    filteredLibraryTags() {
      return this.botTags.filter((tag) => {
        return (
          tag.text
            .toLowerCase()
            .indexOf(this.tagsInput.currentLibraryTag.toLowerCase()) !== -1
        );
      });
    },
  },
  methods: {
    applyResponses() {
      this.$emit("useResponses", this.selectedAnswers);
      this.$bvModal.hide("smartSearchModal");
    },
    emptyList() {
      this.tagsInput.currentQATag = "";
      this.tagsInput.currentLibraryTag = "";
      this.tagsInput.tagsSearch = [];
      this.selectedAnswers = [];
      this.qaQuestion = "";
      this.libraryQuestion = "";
      this.responses = [];
      this.libraryPassages = [];
      this.displayQAAlert = false;
    },
    getBotTags() {
      let botName = this.$router.currentRoute.params.bot;
      LibraryService.getBotTags(botName).then((response) => {
        response.data.forEach((tag) => {
          this.botTags.push({ text: tag });
        });
      });
    },
    searchQuestion(type, text) {
      if(type == 'qa' && !text) {
        this.displayQAAlert = true;
        return;
      }
      this.displayQAAlert = false;
      this.useLoader = true;
      this.responses = [];
      this.allResponses = [];
      this.question = text || this.question;
      this.question = this.question.trim();
      (this.tags = this.tagsInput.tagsSearch.map((tagData) => tagData.text)),
        AskService.ask(this.question, this.tags, this.$route.params.bot)
          .then((response) => {
            type == "qa"
              ? this.useAskData(response)
              : this.searchPassages(
                  this.question,
                  this.tagsInput.tagsSearch,
                  response.data.log_id
                );
          })
          .catch((error) => {
            this.showErrorAlert(
              "There was an error trying to find an answer for your question. Try another one"
            );
            this.resetAsk();
            console.log(error);
          });
    },
    searchPassages(question, tags, logId) {
      AskService.librarySearch(question, tags, logId, this.$route.params.bot)
        .then((response) => {
          this.libraryPassages = response.data;
          this.responseArrived = this.libraryPassages.length != 0;
          if (!this.responseArrived) {
            this.showErrorAlert(
              "We couldn't find an answer for your question. Try another one"
            );
            this.responseArrived = true;
            this.useLoader = false;
          }
          this.useLoader = false;
        })
        .catch((error) => {
          console.log(error);
          this.showErrorAlert(
            "There was an error trying to find an answer for your question. Try another one"
          );
          this.useLoader = false;
        });
    },
    useAskData(response) {
      let data = response.data;
      if (data.original_question) {
        this.question = data.question;
        this.originalQuestion = data.original_question;
      } else {
        this.originalQuestion = null;
      }
      this.logId = data.log_id;
      this.responses = data.questions || [];
      this.responseArrived = this.responses.length != 0;
      this.useLoader = false;
    },
    showErrorAlert(message) {
      this.alerts.ask.errorMsg = message;
      this.alerts.ask.error = !this.alerts.ask.error;
    },
  },
  created() {
    this.getBotTags();
  },
};
</script>

<style lang="scss" scoped>
.margin-smart-search-question-title {
  margin-bottom: 20px;
}

.smart-search-results-row {
  margin-bottom: 30px;

  .smart-search-selected-answers {
    margin-right: 15px;

    .smart-search-selected-count {
      color: var(--viridian);
    }
  }
}

.smart-search-questions-container {
  max-height: 600px;
  overflow: hidden;
  overflow-y: auto;
}

.smart-search-question-block {
  margin-bottom: 30px;
}

.smart-search-visit-source {
  margin-bottom: 10px;
  margin-top: 20px;

  span {
    margin-right: 15px;
  }
}

.smart-search-answer-separator {
  border: 0;
  height: 1px;
  width: 100%;
  margin: 0px 700px 30px 25px;
  background-color: #000;
  opacity: 0.15;
}

.smart-search-tags-title-container {
  width: 100%;
  text-align: left;
  margin-left: 10px;
}

.smart-search-tags-container {
  display: inline-block;
  flex-wrap: wrap;
  flex: 200px;
  margin-left: 25px;
}

.smart-search-tags {
  border: 1px solid #dedede;
  padding: 4px 20px;
  border-radius: 20px;
  margin: 5px;
  display: inline-block;
  max-height: 30px;
}

.smart-search-tags-title-container {
  width: 100%;
  text-align: left;
  margin-left: 10px;
}

.smart-search-answer-tags {
  padding-left: 28px;
}

.smart-search-tags {
  border: 1px solid #dedede;
  padding: 4px 20px;
  border-radius: 20px;
  margin: 5px 5px;
  max-height: 30px;
}

.smart-search-answer-content {
  max-width: 908px;
  margin-bottom: 20px;
}

.smart-search-question {
  color: #09609f;
  margin-bottom: 30px;
}

.smart-search-button {
  border-radius: 0 0.25rem 0.25rem 0;
  background-color: var(--viridian);
  border-color: var(--viridian);
}

.smart-search-button:focus {
  box-shadow: none;
}

.smart-search-modal-question-input {
  max-width: 1125px;
  font-size: 16px;
}

::v-deep {
  div.modal-dialog {
    max-width: 1207px;
    height: auto;
    max-height: 80vh;
  }

  div.modal-backdrop {
    background-color: rgba(255, 255, 255, 0.55);
  }

  header.modal-header {
    border-bottom: none;
    padding-bottom: 0px;
  }

  .custom-control-label span,
  span.cursor-pointer {
    font-family: OpenSans;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--viridian);
  }

  .custom-control-label::before {
    border: 1px solid var(--viridian);
    border-radius: 6px;
  }

  .custom-checkbox
    .custom-control-input:checked
    ~ .custom-control-label::after {
    background-color: var(--viridian);
    border-radius: 6px;
  }

  .custom-control-input:checked + label.custom-control-label span,
  .custom-control.custom-checkbox
    .custom-control-input:checked
    + span.cursor-pointer {
    color: black;
  }

  ul.nav-tabs {
    border-bottom: none;

    li.nav-item a.nav-link {
      color: var(--dark);
    }

    li.nav-item a.nav-link.active {
      color: var(--viridian);
      border: none;
      border-bottom: 1px solid var(--viridian);
    }
  }
}
</style>